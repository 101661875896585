<template>
  <div class="content">
    <div class="card-login">
      <div class="login_box">
        <div class="logo">
          <img src="../assets/image/logo.png" alt />
        </div>
        <div class="loginform">
          <el-tabs v-model="activeName" @tab-click="handleClick" class="top">
            <el-tab-pane label="密码登录" name="mima"></el-tab-pane>
            <el-tab-pane label="验证码登录" name="yanzhengma"></el-tab-pane>
          </el-tabs>
          <div v-if="yanzhengma">
            <el-form
              :model="LoginRuleForm"
              :rules="loginFormRules"
              ref="loginRuleForm"
              class="form-signin"
            >
              <el-form-item class="LoginContent"></el-form-item>
              <el-form-item prop="user">
                <el-input
                  v-model="LoginRuleForm.user"
                  placeholder="请填写手机号码"
                  prefix-icon="el-icon-mobile-phone"
                  @keyup.enter.native="login"
                ></el-input>
              </el-form-item>
              <el-form-item prop="code">
                <el-input
                  v-model="LoginRuleForm.code"
                  placeholder="请填写验证码"
                  prefix-icon="el-icon-edit"
                  @keyup.enter.native="login"
                ></el-input>
                <el-button
                  v-if="showgetsms"
                  type="warning"
                  plain
                  class="SendSmsAction"
                  size="mini"
                  @click="getSms"
                >
                  获取验证码
                </el-button>
                <el-button
                  v-else
                  type="warning"
                  plain
                  class="SendSmsAction"
                  size="mini"
                >
                  {{ countdown }}s之后重发
                </el-button>
                <!--<span class="yanzhengma">验证码：</span>-->
              </el-form-item>
              <el-form-item class="btns">
                <el-button type="primary" class="btn_login" @click="login">
                  登录
                </el-button>
              </el-form-item>
              <div style="display:flex;justify-content:space-between;">
                <el-link target="_blank" @click="forget" type="info">
                  忘记密码
                </el-link>
                <el-link target="_blank" @click="signin" type="info">
                  注册
                </el-link>
              </div>
            </el-form>
          </div>
          <div v-if="mima">
            <el-form
              :model="LoginRuleFormmima"
              :rules="loginFormRulesmima"
              ref="loginFormRulesmima"
              class="form-signin"
            >
              <el-form-item class="LoginContent"></el-form-item>
              <el-form-item prop="user">
                <el-input
                  v-model="LoginRuleFormmima.user"
                  placeholder="请填写您的账号"
                  prefix-icon="el-icon-mobile-phone"
                  @keyup.enter.native="login"
                ></el-input>
              </el-form-item>
              <el-form-item prop="pwd">
                <el-input
                  v-model="LoginRuleFormmima.pwd"
                  placeholder="请填写您的密码"
                  autocomplete="off"
                  prefix-icon="el-icon-edit"
                  @keyup.enter.native="login"
                  show-password
                ></el-input>
                <!--<span class="yanzhengma">验证码：</span>-->
              </el-form-item>
              <el-form-item class="btns">
                <el-button type="primary" class="btn_login" @click="login">
                  登录
                </el-button>
              </el-form-item>
              <div style="display:flex;justify-content:space-between;">
                <el-link target="_blank" @click="forget" type="info">
                  忘记密码
                </el-link>
                <el-link target="_blank" @click="signin" type="info">
                  注册
                </el-link>
              </div>
            </el-form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import loginapi from "../assets/api/login.js";
import { mapActions } from "vuex";
export default {
  mounted() {},
  data() {
    var checkPhone = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("手机号不能为空"));
      } else {
        const reg = /^1[3|4|5|7|8][0-9]\d{8}$/;
        const reg1 = /@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
      }
      var validatePass = (rule, value, callback) => {
        if (value === "") {
          callback(new Error("请输入密码"));
        } else {
          if (this.loginFormRulesmima.checkPass !== "") {
            this.$refs.loginFormRulesmima.validateField("checkPass");
          }
          callback();
        }
      };
    };
    return {
      LoginRuleForm: {
        user: "",
        code: "",
        tokenType: 2,
      },
      loginFormRules: {
        // user: [{ required: true, message: "请输入账号",}],
        code: [
          {
            required: true,
            message: "请输入验证码",
            trigger: "blur",
          },
        ],
      },
      LoginRuleFormmima: {
        user: "",
        pwd: "",
        tokenType: 2,
      },
      loginFormRulesmima: {
        user: [
          {
            required: true,
            message: "请输入账号",
            trigger: "blur",
          },
        ],
        pwd: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur",
          },
        ],
      },
      userInfo: {
        token: "",
        userid: "",
      },
      showgetsms: true,
      countdown: 60,
      yanzhengma: false,
      mima: true,
      activeName: "mima",
    };
  },
  methods: {
    ...mapActions(["Login"]),
    handleClick(event) {
      if (this.activeName === "mima") {
        this.tooglemima();
      } else {
        this.toogleyanzhengma();
      }
    },
    // 切换到验证码模式
    toogleyanzhengma() {
      this.yanzhengma = true;
      this.mima = false;
    },
    // 切换到密码模式
    tooglemima() {
      this.yanzhengma = false;
      this.mima = true;
    },
    // 获取验证码
    async getSms() {
      const reg = /^1[3|4|5|7|8|9][0-9]\d{8}$/;
      if (!reg.test(this.LoginRuleForm.user))
        return this.$message.error("请填写正确手机号，邮箱请转至密码登录");
      if (this.LoginRuleForm.user === "")
        return this.$message.error("请先填写手机号！");
      let captchadata = {
        identifier: this.LoginRuleForm.user,
        issuer: "WEB",
      };
      loginapi.captcha(captchadata).then((res) => {
        this.daojishi();
        this.showgetsms = false;
        this.$message.success("验证码获取成功！");
      });
    },
    // 倒计时
    daojishi() {
      if (this.countdown == 0) {
        this.countdown = 60;
        this.showgetsms = true;
        return;
      }
      setTimeout(() => {
        this.daojishi();
      }, 1000);
      this.countdown--;
    },
    // 登录
    async login() {
      // 表单校验
      let logindata = {
        identifier: this.LoginRuleFormmima.user,
        password: this.$md5(this.LoginRuleFormmima.pwd),
        issuer: "WEB",
        type: "MOBILE",
      };
      let loginCaptcha = {
        identifier: this.LoginRuleForm.user,
        captcha: this.LoginRuleForm.code,
        issuer: "WEB",
        type: "QUICK",
      };
      if (this.yanzhengma === true) {
        this.$refs.loginRuleForm.validate(async (valid) => {
          if (!valid) return this.$message.error("请将信息填写完整");
          loginapi.login(loginCaptcha).then((res) => {
            if (res.code === 200) {
              this.$message.success("登录成功");
              window.localStorage.setItem("token", res.data.token);
              window.localStorage.setItem(
                "refreshToken",
                res.data.refreshToken
              );
              this.$router.push("/Index");
            }
          });
        });
      } else {
        // const reg = /^1[3|4|5|7|8][0-9]\d{8}$/;
        // const reg1 = /@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
        // if (reg.test(this.LoginRuleFormmima.user)) {
        //   logindata.type = "MOBILE";
        // }
        // if (reg1.test(this.LoginRuleFormmima.user)) {
        //   logindata.type = "EMAIL";
        // }
        // if (
        // 	!reg1.test(this.LoginRuleFormmima.user) &&
        // 	!reg.test(this.LoginRuleFormmima.user)
        // ) {
        // 	return this.$message.error("请填写正确的手机号或者邮箱");
        // }
        this.$refs.loginFormRulesmima.validate(async (valid) => {
          if (!valid) return this.$message.error("请将信息填写完整");
          loginapi.login(logindata).then((res) => {
            if (res.code === 200) {
              this.$message.success("登录成功");
              window.localStorage.setItem("token", res.data.token);
              window.localStorage.setItem(
                "refreshToken",
                res.data.refreshToken
              );
              this.$router.push("/Index");
            }
          });
        });
      }
    },
    forget() {
      this.$router.push("/Forget");
    },
    signin() {
      this.$router.push("/Register");
    },
  },
};
</script>
<style scoped>
.content {
  background: url(../assets/image/bg.png);
  width: 100%;
  height: 100%;
  position: absolute;
  background-size: 100% 100%;
}

.card-login {
  background: url(../assets/image/yuan.png) no-repeat center center;
  position: relative;
  height: 100%;
  margin: 0 auto;
  overflow: hidden;
  z-index: 4;
  position: relative;
}

.login_box {
  width: 420px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.logo {
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
}

.loginform {
  width: 420px;
  height: 350px;
  right: calc((100% - 900px) / 2);
  margin: auto;
}

.el-form-item__content {
  font-size: 36px !important;
  margin-left: 0 !important;
  text-align: center;
}

.loginform >>> .el-tabs__nav-wrap::after {
  position: static !important;
}

/* .el-tabs__active-bar {
  background-color: transparent !important;
} */
.top {
  padding-left: 28% !important;
  margin: -35px 0;
}

.el-tabs__nav-wrap::after {
  left: 50% !important;
  transform: translate(-50%) !important;
  margin: auto !important;
}

.el-tabs__header {
  margin: auto !important;
  width: 80% !important;
}

.form-signin {
  padding: 25px 35px;
  right: calc((100% - 900px) / 2);
  z-index: 10;
  display: block;
  box-sizing: border-box;
}

.el-input {
  border: #0684bc;
}

.btn_login {
  width: 100%;
  margin-top: 25px;
}

.el-tabs__item {
  width: 50% !important;
  text-align: center !important;
}

.el-tabs__nav {
  width: 100% !important;
}

.el-form-item {
  margin-bottom: 30px !important;
}

.el-form-item .yanzhengma {
  position: absolute;
  left: 5px;
  top: 3px;
  width: 80px;
  line-height: 40px;
  font-size: 14px;
}

.SendSmsAction {
  position: absolute;
  right: 5px;
  top: 5px;
}

.el-tabs {
  margin-top: 50px;
}

.down {
  margin: 20px 0 0 30px;
}

.down a {
  font-size: 24px;
  text-decoration: none;
  color: #23527c;
  position: relative;
}

.erweima {
  display: none;
  position: absolute;
  top: 156%;
  z-index: 99;
  left: 2%;
  width: 150px;
  border-radius: 8px;
}

.jiantou {
  border: 15px solid transparent;
  border-bottom: 15px solid #fff;
  width: 0;
  height: 0;
  position: absolute;
  left: 53%;
  top: 60%;
  display: none;
}

.erweima::before {
  width: 30px;
  height: 30px;
  background-color: pink;
}

.down a:hover {
  color: #fff;
}

.down a:hover .erweima,
.down a:hover .jiantou {
  display: block;
}
</style>
